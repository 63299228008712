import React, { useState, useRef, useEffect } from 'react'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Search from '../../components/SearchContainer';
import './../../sass/main.scss'
import logoRona from '../../images/logo-rona.png';
import logoRenoDepot from '../../images/logo-renodepot-en.png';
import sscPositionLogo from '../../images/SSC-Position-logo.png';
import sscPositionLogoFR from '../../images/SCC-positions-french.svg'
import banner from '../../images/SPR-712_Bandeau_2160x550_Recrutement_EN_V2.jpg';
import bannerFR from '../../images/SPR-712_Bandeau_2160x550_Recrutement_FR_V2.jpg';
import financeIcon from '../../images/finance-icon.svg';
import hrIcon from '../../images/HR-icon.svg';
import itIcon from '../../images/IT-Icon.svg';
import storeIcon from '../../images/Store-Ops-icon.svg';
import supplyChainIcon from '../../images/Supply-chain-icon.svg';
import marketingIcon from '../../images/marketing-icon.svg';
import merchIcon from '../../images/merch-icon.svg';
import commsIcon from '../../images/comms-icon.svg';
import tony from '../../images/tony.png'
import marc from '../../images/marc.png'
import closeButton from '../../images/close-button.svg'

import videoTonyFR from '../../images/Tony Cioffi - French.mp4'
import videoMarcFR from '../../images/Marc Macdonald.mp4'
import videoTonyEN from '../../images/Tony-Cioffi.mp4'

const CareerFairPage = () => {
    let isFrench = process.env.LANG === 'FR';
    const [videoURL, setVideoURL] = useState();
    const [showLightBox, setShowLightBox] = useState( false );
    const closeLightBox = () => {
        document.getElementById( 'lightbox_video' ).pause();
        setShowLightBox( false );
    }
    const openLightBox = ( e ) => {
        setShowLightBox( true );
        document.getElementById( 'lightbox_video' ).play();
        setVideoURL( e.target.getAttribute( 'video' ) );
    }
    return (<>
        <Header
            title={isFrench ? 'Consultez toutes les offres d\'emploi disponible | RONA inc., Rona, et Réno Dépôt' : 'Search all Available Jobs | RONA inc., Rona, and Reno Depot'}
            description={isFrench ? 'Consultez toutes les opportunités d\'emploi disponible | RONA inc., Rona, et Réno Dépôt' : 'Search all available job opportunities with RONA inc., Rona, and Réno Dépôt here.'}
            breadcrumbs={[{
                text: process.env.LANG === 'FR' ? `Salon de carrière` : `Career Fair`,
                url: process.env.LANG === 'FR' ? `/salon-carriere` : `/career-fair`
            }]}
            hidden={false}
            redirect={process.env.INT_EXT !== 'internal' ? process.env.LANG !== 'FR' ? `${process.env.EN_URL}/search` : `${process.env.FR_URL}/recherche` : false}
            lang={process.env.LANG ? process.env.LANG : 'en'}
            lang_link={process.env.LANG === 'FR' ? `${process.env.EN_URL}/career-fair` : `${process.env.FR_URL}/salon-carriere`}
            bodyClasses={'page-search page-career-fair'}/>
        <main id={'main'}>
            <section style={{padding: 0}} className={'c tac container-xxl'}>
                <div className="r-c-lg my6 my3-sm px2-sm jsb ac">
                    <h1 className={'my4-lg'}>{isFrench ? 'Venez discuter des opportunités à l\'interne!' : 'Let\'s discuss job opportunities!'}</h1>
                    <img src={isFrench ? sscPositionLogoFR : sscPositionLogo} className={'mw100'} width={'442'}
                         alt={'SSC Position Logo'}/>
                </div>
                <img src={isFrench ? bannerFR : banner} width={'100%'} alt={isFrench ? 'Bannière' : 'Banner'}/>
                <div className={showLightBox ? 'lightbox c jc ac' : 'lightbox c jc ac dn'}>
                        <div className={'video-content c jc afe '}>
                            <a className={'tar c jac ac'} onClick={closeLightBox}>
                                <img src={closeButton} alt={'Close Button'}/>
                            </a>
                            <video src={videoURL} id={'lightbox_video'} autoPlay controls/>
                        </div>
                    </div>
                {/*<div className={'r-c-lg jc ac mt5'}>*/}
                {/*    {isFrench ?*/}
                {/*        <>*/}
                {/*            <div className="c ac w50 w100-lg jc mt5">*/}
                {/*                <img height={200} src={tony} alt={'Tony\'s Headshot'}/>*/}
                {/*                <h2>Un message de Tony Cioffi, Président,<br/>Lowe's Canada</h2>*/}
                {/*                <button onClick={openLightBox} video={videoTonyFR} className={'button'} style={{background: '#0075C9'}}>Regardez le message de Tony*/}
                {/*                </button>*/}
                {/*            </div>*/}
                {/*            <div className="c ac w50 w100-lg jc mt5">*/}
                {/*                <img height={200} src={marc} alt={'Marc\'s Headshot'}/>*/}
                {/*                <h2>Un message de Marc Macdonald, Vice-président principal, Ressources humaines</h2>*/}
                {/*                <button onClick={openLightBox} video={videoMarcFR} className={'button'} style={{background: '#0075C9'}}>Regardez le message de Marc*/}
                {/*                </button>*/}
                {/*            </div>*/}
                {/*        </> :*/}
                {/*        <div className="c ac jc">*/}
                {/*            <img height={200} src={tony} alt={'Tony\'s Headshot'}/>*/}
                {/*            <h2>A message from Tony Cioffi, President,<br/>Lowe's Canada</h2>*/}
                {/*            <button onClick={openLightBox} video={videoTonyEN} className={'button'} style={{background: '#0075C9'}}>Watch Tony's Message</button>*/}
                {/*        </div>}*/}

                {/*</div>*/}
                <h2 className={'my6'}>{isFrench ? "Nous n'avons pas actuellement d'événements internes. Veuillez consulter notre page de carrière interne: " : 'We do not currently have any internal events. Please visit our internal career page: '}<a href={isFrench ? "https://lowes-internal-fr.careerpagedemo.com" : "https://lowes-internal.careerpagedemo.com/"}>{!isFrench ? "lowes-internal.careerpagedemo.com": "lowes-internal-fr.careerpagedemo.com"}</a></h2>
                {/*<h2 className={'mt6'}>{isFrench ? 'Venez nous rencontrer pour discuter avec les gestionnaires du Centre de soutien aux magasins ou inscrivez vous en ligne pour discuter virtuellement avec un membre de l\'équipe d\'acquisition de talents sur les opportunités de carrière.' : 'Come and talk to our SSC managers in Boucherville or register online to discuss with talent acquisition about career opportunities.'}</h2>*/}
            </section>
            {/*<section className={'mt6 mb3 my4-sm c jc ac'} style={{background: '#F8F8F8'}}>*/}
            {/*    <div className={'container-xxl c tac jc ac'}>*/}
            {/*        <h2 className={'mt6'}>{isFrench ? 'Explorez les opportunités de carrière chez Rona Canada' : 'Grow with Rona Canada'}</h2>*/}
            {/*        <span*/}
            {/*            style={{fontSize: '20px'}}>{isFrench ? 'Inscrivez vous ici en choisissant le département qui vous intéresse!' : 'Register here by selecting the department of interest for you!'}</span>*/}
            {/*        <div className={'c mw100 jc ac my6'}>*/}
            {/*            <div className="r-c-lg w100 jc ac ">*/}
            {/*                <div className="card c mw100 jc asb bs mx3 my3 my6-lg my6-lg">*/}
            {/*                    <div className="card-top c ac jc h100">*/}
            {/*                        <img src={financeIcon} className={'blue-circle mb4'}/>*/}
            {/*                        <span className={'roles-in tac'}>{isFrench ? 'Rôles en' : 'Roles In'}</span>*/}
            {/*                        <span className={'sector mt2 tac'}>{isFrench ? 'Finance' : 'Finance'}</span>*/}
            {/*                        /!*<span*!/*/}
            {/*                        /!*    className={'desc w100 px3 tac'}>Learn the ins-and-outs of procurement & operations</span>*!/*/}
            {/*                    </div>*/}
            {/*                    <div className="card-bottom c jc ac">*/}
            {/*                        <a target="_blank"*/}
            {/*                           href={isFrench ? 'https://lowes.hiringplatform.ca/72431-finance/262459-application-form/fr_CA' : 'https://lowes.hiringplatform.ca/72431-finance/262459-application-form/en'}*/}
            {/*                           className={'c jc ac'}>*/}
            {/*                            <button*/}
            {/*                                className={'button'}>{isFrench ? 'S\'inscrire maintenant' : 'Register Now'}</button>*/}
            {/*                        </a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="card c mw100 jc asb bs mx3 my5">*/}
            {/*                    <div className="card-top c ac jc h100">*/}
            {/*                        <img src={hrIcon} className={'blue-circle mb4'}/>*/}
            {/*                        <span className={'roles-in tac'}>{isFrench ? 'Rôles en' : 'Roles In'}</span>*/}
            {/*                        <span*/}
            {/*                            className={'sector mt2 tac'}>{isFrench ? 'Ressources humaines' : 'Human Resources'}</span>*/}
            {/*                        /!*<span*!/*/}
            {/*                        /!*    className={'desc w100 px3 tac'}>Help us find the talent behind the brand</span>*!/*/}
            {/*                    </div>*/}
            {/*                    <div className="card-bottom c jc ac">*/}
            {/*                        <a target="_blank"*/}
            {/*                           href={isFrench ? 'https://lowes.hiringplatform.ca/72434-human-resources/262464-application-form/fr_CA' : 'https://lowes.hiringplatform.ca/72434-human-resources/262464-application-form/en'}*/}
            {/*                           className={'c jc ac'}>*/}
            {/*                            <button*/}
            {/*                                className={'button'}>{isFrench ? 'S\'inscrire maintenant' : 'Register Now'}</button>*/}
            {/*                        </a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="r-c-lg w100 jc ac">*/}
            {/*                <div className="card c mw100 jc asb bs mx3 my5">*/}
            {/*                    <div className="card-top c ac jc h100">*/}
            {/*                        <img src={itIcon} className={'blue-circle mb4'}/>*/}
            {/*                        <span className={'roles-in tac'}>{isFrench ? 'Rôles en' : 'Roles In'}</span>*/}
            {/*                        <span*/}
            {/*                            className={'sector mt2 tac'}>{isFrench ? 'Technologie de l\'information' : 'Information Technology'}</span>*/}
            {/*                        /!*<span*!/*/}
            {/*                        /!*    className={'desc w100 px3 tac'}>Work in the cutting edge of Server-side technologies & software</span>*!/*/}
            {/*                    </div>*/}
            {/*                    <div className="card-bottom c jc ac">*/}
            {/*                        <a target="_blank"*/}
            {/*                           href={isFrench ? 'https://lowes.hiringplatform.ca/72437-information-technology/262471-application-form/fr_CA' : 'https://lowes.hiringplatform.ca/72437-information-technology/262471-application-form/en'}*/}
            {/*                           className={'c jc ac'}>*/}
            {/*                            <button*/}
            {/*                                className={'button'}>{isFrench ? 'S\'inscrire maintenant' : 'Register Now'}</button>*/}
            {/*                        </a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="card c mw100 jc asb bs mx3 my5">*/}
            {/*                    <div className="card-top c ac jc h100">*/}
            {/*                        <img src={storeIcon} className={'blue-circle mb4'}/>*/}
            {/*                        <span className={'roles-in tac'}>{isFrench ? 'Rôles en' : 'Roles In'}</span>*/}
            {/*                        <span*/}
            {/*                            className={'sector mt2 tac'}>{isFrench ? 'Support aux opérations de magasin' : 'Store Operations and Support'}</span>*/}
            {/*                        /!*<span*!/*/}
            {/*                        /!*    className={'desc w100 px3 tac'}>Help us bring in the talented people we expect at Lowes</span>*!/*/}
            {/*                    </div>*/}
            {/*                    <div className="card-bottom c jc ac">*/}
            {/*                        <a target="_blank"*/}
            {/*                           href={isFrench ? 'https://lowes.hiringplatform.ca/72438-store-operations-and-support/262473-application-form/fr_CA' : 'https://lowes.hiringplatform.ca/72438-store-operations-and-support/262473-application-form/en'}*/}
            {/*                           className={'c jc ac'}>*/}
            {/*                            <button*/}
            {/*                                className={'button'}>{isFrench ? 'S\'inscrire maintenant' : 'Register Now'}</button>*/}
            {/*                        </a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="r-c-lg w100 jc ac">*/}
            {/*                <div className="card c mw100 jc asb bs mx3 my5">*/}
            {/*                    <div className="card-top c ac jc h100">*/}
            {/*                        <img src={supplyChainIcon} className={'blue-circle mb4'}/>*/}
            {/*                        <span className={'roles-in tac'}>{isFrench ? 'Rôles en' : 'Roles In'}</span>*/}
            {/*                        <span*/}
            {/*                            className={'sector mt2 tac'}>{isFrench ? 'Chaîne d\'approvisionnement' : 'Supply Chain'}</span>*/}
            {/*                        /!*<span*!/*/}
            {/*                        /!*    className={'desc w100 px3 tac'}>Keep our locations current, and help keeping things moving</span>*!/*/}
            {/*                    </div>*/}
            {/*                    <div className="card-bottom c jc ac">*/}
            {/*                        <a target="_blank"*/}
            {/*                           href={isFrench ? 'https://lowes.hiringplatform.ca/72439-supply-chain/262474-application-form/fr_CA' : 'https://lowes.hiringplatform.ca/72439-supply-chain/262474-application-form/en'}*/}
            {/*                           className={'c jc ac'}>*/}
            {/*                            <button*/}
            {/*                                className={'button'}>{isFrench ? 'S\'inscrire maintenant' : 'Register Now'}</button>*/}
            {/*                        </a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="card c mw100 jc asb bs mx3 my5">*/}
            {/*                    <div className="card-top c ac jc h100">*/}
            {/*                        <img src={marketingIcon} className={'blue-circle mb4'}/>*/}
            {/*                        <span className={'roles-in tac'}>{isFrench ? 'Rôles en' : 'Roles In'}</span>*/}
            {/*                        <span className={'sector mt2 tac'}>{isFrench ? 'Marketing' : 'Marketing'}</span>*/}
            {/*                        /!*<span*!/*/}
            {/*                        /!*    className={'desc w100 px3 tac'}>Get to know our customers, demographics and regions</span>*!/*/}
            {/*                    </div>*/}
            {/*                    <div className="card-bottom c jc ac">*/}
            {/*                        <a target="_blank"*/}
            {/*                           href={isFrench ? 'https://lowes.hiringplatform.ca/72441-marketing/262479-application-form/fr_CA' : 'https://lowes.hiringplatform.ca/72441-marketing/262479-application-form/en'}*/}
            {/*                           className={'c jc ac'}>*/}
            {/*                            <button*/}
            {/*                                className={'button'}>{isFrench ? 'S\'inscrire maintenant' : 'Register Now'}</button>*/}
            {/*                        </a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="r-c-lg w100 jc ac">*/}
            {/*                <div className="card c mw100 jc asb bs mx3 my5">*/}
            {/*                    <div className="card-top c ac jc h100">*/}
            {/*                        <img src={merchIcon} className={'blue-circle mb4'}/>*/}
            {/*                        <span className={'roles-in tac'}>{isFrench ? 'Rôles en' : 'Roles In'}</span>*/}
            {/*                        <span*/}
            {/*                            className={'sector mt2 tac'}>{isFrench ? 'Mise en marché' : 'Merchandising'}</span>*/}
            {/*                        /!*<span*!/*/}
            {/*                        /!*    className={'desc w100 px3 tac'}>Working with companies to keep our warehouses current</span>*!/*/}
            {/*                    </div>*/}
            {/*                    <div className="card-bottom c jc ac">*/}
            {/*                        <a target="_blank"*/}
            {/*                           href={isFrench ? 'https://lowes.hiringplatform.ca/72442-merchandising/262480-application-form/fr_CA' : 'https://lowes.hiringplatform.ca/72442-merchandising/262480-application-form/en'}*/}
            {/*                           className={'c jc ac'}>*/}
            {/*                            <button*/}
            {/*                                className={'button'}>{isFrench ? 'S\'inscrire maintenant' : 'Register Now'}</button>*/}
            {/*                        </a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="card c mw100 jc asb bs mx3 my5">*/}
            {/*                    <div className="card-top c ac jc h100">*/}
            {/*                        <img src={commsIcon} className={'blue-circle mb4'}/>*/}
            {/*                        <span className={'roles-in tac'}>{isFrench ? 'Rôles en' : 'Roles In'}</span>*/}
            {/*                        <span*/}
            {/*                            className={'sector mt2 tac'}>{isFrench ? 'Communications, affaires publiques et marchands affiliés' : 'Communications / Public Affairs / Dealers'}</span>*/}
            {/*                        /!*<span*!/*/}
            {/*                        /!*    className={'desc w100 px3 tac'}>Be one of the voices behind of Lowes Canada</span>*!/*/}
            {/*                    </div>*/}
            {/*                    <div className="card-bottom c jc ac">*/}
            {/*                        <a target="_blank"*/}
            {/*                           href={isFrench ? 'https://lowes.hiringplatform.ca/72443-communications-public-affairs-and-dealers/262481-application-form/fr_CA' : 'https://lowes.hiringplatform.ca/72443-communications-public-affairs-and-dealers/262481-application-form/en'}*/}
            {/*                           className={'c jc ac'}>*/}
            {/*                            <button*/}
            {/*                                className={'button'}>{isFrench ? 'S\'inscrire maintenant' : 'Register Now'}</button>*/}
            {/*                        </a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*<section className={'container-xxl tac c jc ac py4'}>*/}
            {/*    <h2>{isFrench ? 'Cliquer ici pour voir toutes les opportunités au centre de soutien aux magasins' : 'Click below to see all SSC opportunities'}</h2>*/}
            {/*    <a target={'_blank'} href={isFrench ? '/recherche/?type=head&term=' : '/search/?type=head&term='}>*/}
            {/*        <button*/}
            {/*            className={'button'}>{isFrench ? 'Voir les postes disponibles' : 'View All Openings'}</button>*/}
            {/*    </a>*/}
            {/*</section>*/}
        </main>
        <Footer lang={process.env.LANG ? process.env.LANG : 'en'}/>
    </>)
}

export default CareerFairPage;


